<template>
  <div class='index'>
    <div class="side_menu">
      <!-- 二级菜单 -->
      <SideMenu :menus="submenu" />
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu'
export default {
  components: { SideMenu },
  data () {
    return {
      submenu: []
    }
  },
  created () {
    this.getSubMenuList()
  },
  methods: {
    // 获取子菜单数据
    getSubMenuList () {
      const list = this.$store.state.menuList
      for (let index = 0; index < list.length; index++) {
        const path = list[index].menuPath
        const children = list[index].children
        if (this.$route.path.includes(path)) {
          const newMenus = children
          this.submenu = this.filterMenuType(newMenus)
        }
      }
    },
    // 递归过滤按钮
    filterMenuType (data) {
      return data.map(item => ({
        ...item,
        children: (item.children || []).length ? this.filterMenuType(item.children.map(child => ({
          ...child,
          children: child.children ? this.filterMenuType(child.children) : []
        })).filter(child => child.menuType !== '1')) : []
      }));
    },
  },
}
</script>

<style scoped lang='scss'>
.index {
  display: flex;
  width: 100%;
  max-width: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  .side_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 146px;
    height: calc(100vh - 194px);
    padding: 10px 0 10px 10px;
    margin-right: 20px;
    border-radius: 4px;
    background: linear-gradient(180deg, #0BA0BB, #52E9B9);
    transform: translate(20px, 194px);
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .content {
    width: 100%;
    max-width: 100%;
    min-height: 600px;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    background-color: #fff;
    padding-bottom: 15px;
    margin-left: 166px;
    overflow: auto;
  }
}
</style>
